import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/orphan.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'
import More from '../components/more'

class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Orphan";
    return (
      <Layout >
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Wants to fit in"/>
        <div id="main"   >
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>
            <h2>{name}</h2>
            
            <p>The Orphan archetype is not just a literary construct, it is a reality that lives within each and every one of us. It is the wounded child within, the one that expects very little from life and has learned to survive through empathy, realism, and street smarts. But make no mistake, this archetype is not to be underestimated. It has the power to control and slowly ruin your life and relationships if you do not learn to integrate it.
Think of the Orphan as a force that seeks to regain the comfort of the womb and neonatal safety in the arms of loving parents. It is a force that will stop at nothing to fulfill this quest, even if it means going through the agonies of the developmental stages that were missed. And while the Orphan's strength lies in its interdependence and pragmatic realism, it also poses a grave hazard. It can lead to a victim mentality, trapping you in a never-ending cycle of helplessness and despair.
But there is hope. With the right guidance, you can learn to harness the power of the Orphan archetype and use it to your advantage. I highly recommend reading our e-book, <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=orphan"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> for a comprehensive understanding of this archetype and how to overcome its negative effects. Don't let the Orphan control your life, take control of it and become the hero of your own story.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
